$primary-color: rgb(54, 172, 250);

.member-image {
  border-radius: 8%;
  height: 280px;
  max-width: 100%;
  height: 280px;
  padding: 2%;
  object-fit: cover;
  object-position: top;
}

.team-btn {
  background-color: inherit;
  border: 0;
}

.card-text {
  //padding-left: 2%;
  // padding-top: 2%;
  // text-align: left;
}

.card-body {
  text-align: center;
}

.maps-div {
  display: flex;
}

.mapsApi {
  height: 100%;
  width: 100%;
  border-radius: 25px;
}

.formatted-address {
  white-space: pre-line;
}

.contact-card {
  // width: auto;
  margin-right: auto;
  margin-left: auto;
}

.join-team {
  padding: 1.79%;
  position: relative;
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}

#join-lbl {
  white-space: nowrap;
}

#join-lbl:hover + #join-btn {
  border: 2px solid $primary-color;
  color: $primary-color;
}

.social-tab {
  border-left-color: white;
  border-left-style: solid;
  padding-left: 2px;
}

.mode {
  padding: 0px 2px 0px 0px;
  color:black !important
}

#join-btn {
  margin-left: 2%;
}

li:hover.switch-btn {
  color: $primary-color;
}

a:hover.schedule-redirect {
  color: $primary-color !important;
  text-decoration: underline;
  cursor: pointer;
}

.dark-maps {
  filter: invert(90%);
}

.scroll-table {
  height: 11em;
  overflow-y: auto;
  scrollbar-width: thin;
}

.card-rounded {
  border-radius: 1.1em;
}

.breif-list {
  list-style-type: none;
  text-transform: capitalize;
}
.rhap_container {
  background: lightgrey !important;
}

.rhap_progress-section .rhap_time {
  color: black;
}
.rhap_main-controls-button svg {
  color: $primary-color;
}

.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: $primary-color !important;
}
.rhap_progress-bar-show-download {
  background-color: darkgray;
}

.typewriter_text {
  font-family: Arial, Helvetica, sans-serif;
  line-height: inherit;
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .bg-img-ios {
    background-size: 100vh !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .align-logo{
    padding-left: 0  !important;
  }
}
.align-logo{
  padding-left: 0.2vw;
}
.text-yellow{
  color: #FFFF00 !important;
}
.contact-btn{
  border-color: #FFFF00 !important;
  color: #FFFF00 !important;
}
.contact-btn:hover{
  background-color: #ffff00 !important;
  color: $primary-color !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.center-item {
  display: flex;
  justify-content: center;
}
.services-row{
  justify-content: space-around !important;
}
@media (max-width: 768px) {
  .navbar-nav {
    margin-top: 1rem;
  }

  .social-icons {
    margin-left: auto;
  }

  .primary-menu .container-fluid {
    flex-wrap: nowrap;
  }

  .primary-menu .d-flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .primary-menu .navbar-toggler {
    margin-left: auto;
  }
}